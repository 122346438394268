import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import { FaMapMarkerAlt } from "react-icons/fa";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { ResponsiveWidthContainer } from "../components/Section";
import Modal from "../components/Modal";
import CareersForm from "../components/ContactForm";
import { colors as c } from "../style";

const SectionTitle = styled.h2`
  text-align: left;
  font-size: 3rem;
  color: white;
  margin: 3rem auto;
  padding-top: 2rem;
  @media all and (min-width: 480px) {
    padding-top: 5rem;
  }
  font-weight: 400;
  line-height: 3rem;
`;

const AddressBlock = ({ name, address }) => {
  const containerStyle = `
    text-align: center;
  `;

  const addressStyle = `
    white-space: pre-wrap;
    text-align: left;
    color: ${c.section_sub_heading};
    font-size: 1.5rem;
    width: fit-content;
    margin: 3rem auto;
    @media all and (min-width: 480px) {
      margin: 0 auto;
    }
    h4 {
      margin: 0;
      font-weight: normal;
      margin-bottom: 1rem;
    }
    div {
      color: ${c.text_main_sub_2};
      padding-left: 4.5rem;
    }
  `;

  return (
    <div css={containerStyle}>
      <address css={addressStyle}>
        <h4>
          <FaMapMarkerAlt
            css={`
              font-size: 3rem;
              margin-right: 1rem;
            `}
          />{" "}
          {name}
        </h4>
        <div>{address}</div>
      </address>
    </div>
  );
};

AddressBlock.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string
};

const AddressBlockContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 4rem auto;

  > * {
    flex-grow: 1;
  }

  > *:first-child {
    @media all and (min-width: 480px) {
      border-right: 3px solid gray;
    }
  }
`;

const buttonStyle = `
  color: currentColor;
  background-color: transparent;
  border: 2px solid currentColor;
  cursor: pointer;
  border-radius: 12px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  margin: 0 auto;
  border: 1px solid white;
  padding: 10px 20px;
  margin: 2rem  0 2rem auto;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
  }
`;

const StyledResponsiveWidthContainer = styled(ResponsiveWidthContainer)`
  > section {
    min-height: 670px;
    justify-content: flex-end;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }
`;

export const ContactPageTemplate = ({ title, bgImage, addresses }) => {
  const [showModal, setShowModal] = useState(false);

  const submitForm = (email, phone, message) => {
    alert(`${email}\n${phone}\n${message}\n`);
    setShowModal(false);
  };

  return (
    <>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <CareersForm onSubmit={submitForm} />
      </Modal>
      <StyledResponsiveWidthContainer
        bgImage={bgImage || null}
        minHeight="770px"
      >
        <SectionTitle>{title}</SectionTitle>
        <button
          onClick={() => setShowModal(true)}
          type="button"
          css={buttonStyle}
        >
          Contact us
        </button>
      </StyledResponsiveWidthContainer>
      <ResponsiveWidthContainer>
        <AddressBlockContainer>
          {addresses.map(x => (
            <AddressBlock key={x.node.name} {...x.node} />
          ))}
        </AddressBlockContainer>
      </ResponsiveWidthContainer>
    </>
  );
};

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  bgImage: PropTypes.string,
  addresses: PropTypes.array
};

const ContactPage = ({ data, location }) => {
  const { markdownRemark: post, allContactYaml: addresses } = data;

  return (
    <Layout location={location}>
      <ContactPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        bgImage={post.frontmatter.backgroundImage}
        addresses={addresses.edges}
        content={post.html}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default ContactPage;

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        backgroundImage
      }
    }
    allContactYaml {
      edges {
        node {
          name
          address
        }
      }
    }
  }
`;
